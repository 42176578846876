
<template>
  <div class="container-fluid">
    <!--Table 5-->
    <div class="col-12 table-title">
      Nombre del Curso: Elaboración de informes de resultados
    </div>
    <div class="col-12 table-white">
      <b>Objetivo:</b>
      <ul>
        <li>
          Capacitar para afrontar con eficiencia la elaboración de informes de
          resultados, ordenando sus contenidos, redactándolos de forma precisa y
          presentándolos en el tiempo establecido de acuerdo con la metodología
          establecida en los términos de referencia diseñado para ello.
        </li>
      </ul>
    </div>
    <div class="col-12 table-blue">
      <b>Descripción:</b><br />
      Los asistentes conocerán en profundidad los distintos tipos de informes y
      su estructura; los recursos y técnicas más usuales para su elaboración; y
      las claves para identificar y corregir errores, derivadas de la
      metodología elaborada en los términos de referencias para ello.
    </div>
    <div class="col-12 table-white">
      <b>Características:</b><br />
      Dirigido a: Profesionistas, independientes, consultores, académicos,
      estudiantes, titulares de las Unidades de Información, Planeación,
      Programación y Evaluación (UIPPE), similares de los entes gubernamentales
      federales, estatales o municipales, o personal designado interesado en
      conocer como se diseñan los informes de resultados.
      <br /><br />
      Garantía de satisfacción: Avalamos la transmisión de conocimientos a
      través der una metodología que permita elegir la información para el
      diseño de productos o resultados.<br /><br />
      Constancia: Al término del curso se otorgará una constancia de
      participación con valor curricular, con la insignia de Visión y Estrategia.
    </div>
    <div class="col-12 table-blue">
      <b>Temario:</b>
      <ol>
        <li>
          Descripción específica del informe de resultados.
          <ul class="ul-clean">
            <li>1.1. Características del programa.</li>
            <li>1.2. Diseño.</li>
            <li>1.3. Planeación y orientación a resultados.</li>
            <li>1.4. Cobertura y Focalización.</li>
            <li>1.5. Operación</li>
            <li>1.6. Percepción de la población atendida.</li>
            <li>1.7. Medición de resultados.</li>
            <li>
              1.8. Análisis de Fortalezas, Oportunidades, Debilidades, Amenazas
              y Recomendaciones.
            </li>
            <li>1.9. Conclusiones.</li>
            <li>1.10. Ficha técnica.</li>
            <li>1.11. Anexos.</li>
          </ul>
        </li>
      </ol>
    </div>
    <div class="col-12 table-white">
      <b>Horas de duración: </b>2 días 8 horas (4 horas por día)
    </div>
    <div class="col-12 table-blue">
      <b>Fecha de realización: </b>5 y 6 de junio de 2023
    </div>
    <!--Table 5 end-->
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  
  