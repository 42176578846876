<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          El valor público en las organizaciones gubernamentales del siglo XXI
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      El valor público en las organizaciones gubernamentales del siglo XXI
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/government.jpeg"
          class="w-100 my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          <b>
            <em>
              No pidas retos a la altura de tu fuerza; pide fuerza a la altura
              de tus retos. El Capitán América.
            </em>
          </b>
        </p>
        <p>
          El término “valor público” se popularizó a raíz de los trabajos de
          Mark H. Moore, (Creating Public Value; Strategic Managment in
          Government) para denotar la necesidad de lograr mejoras considerables
          en la administración pública. Propone la utilización de la
          administración estratégica en vez de doctrinas tradicionales para
          crear instituciones que respondan de manera más efectiva a las
          necesidades de los ciudadanos(as). El impacto que esta doctrina ha
          tenido se deriva del “deseo genuino de los gobiernos para entender las
          cosas que le importan a la gente”.
        </p>
        <p>
          En la vida actual es una preocupación de los buenos administradores
          públicos encontrar resultados que estén orientados a satisfacer las
          necesidades de sus usuarios y no solo a sus jefes como
          tradicionalmente se venía haciendo.
        </p>
        <p>
          Los nuevos directivos, los más jóvenes, han encontrado en la
          Administración Gubernamental para Resultados (AGpR) la metodología más
          idónea para tal propósito, por tratarse de un modelo de innovación
          organizacional que refleja el valor agregado en los bienes, servicios
          y regulaciones, a partir de lograr hacer los análisis apropiados. Otro
          de sus rasgos importantes es que se debe entender la necesidad que
          tienen las oficinas públicas de planear su trabajo cotidiano, no solo
          de cumplir con las funciones encomendadas, sino para lograr los
          impactos esperados en los programas gubernamentales.
        </p>
        <p>
          AGpR exige el diseño de programas de trabajo integrados a partir de la
          construcción de objetivos enfocados a resultados, incorporando un
          mejor uso de estrategias, riesgos, indicadores y metas, así como la
          necesidad de crear en los funcionarios la capacidad de comunicar
          adecuadamente cada una de las partes del programa, mediante la
          definición precisa de una cadena de valor. En este último punto,
          destierra para siempre la vieja práctica de comunicarse con los
          usuarios solo mediante los tradicionales boletines de prensa, ahora
          cada funcionario debe encontrar los canales necesarios para comunicar
          su trabajo.
        </p>
        <p>
          Actualmente solo algunas dependencias lideres están en posibilidades
          de aprovechar la metodología AGpR en la integración de sus planes
          estratégicos, planes operativos, riesgos, proyectos y procesos; desde
          su formulación más temprana, durante su implementación, así como en la
          permanencia y planeación de los procesos de comunicación de tales
          resultados.
        </p>
        <p>
          La falta de un mayor uso de AGpR no limita que cuando se utiliza lleva
          a los gobiernos a tener procesos más modernos de organización del
          trabajo y una cultura de
          <b
            >mejora continua, dado que está entrelazada con las políticas de
            productividad y competitividad laboral.</b
          >
        </p>
        <p>
          En los siguientes años las personas que trabajen en puestos de
          dirección pública deberán tener habilidad para desarrollar una cultura
          de monitoreo y evaluación que fortalezca la incorporación del enfoque
          de Administración Gubernamental para Resultados, a través de la
          planeación, seguimiento, evaluación y comunicación de una gestión
          pública de calidad, orientada a resultados y centrada en la ciudadanía
          y/o usuarios de los programas.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>