<template>
  <div class="container-fluid">
    <h1 class="my-h1">En que consiste</h1>
    <h2 class="my-h2">Presupuesto basado en Resultados</h2>
    <div class="general-text">
      <ul>
        <li>
          Es una metodología que permite mejorar la calidad del gasto público y
          promover una adecuada rendición de cuentas.
        </li>
        <li>
          Admite vincular los procesos de toma de decisiones y de asignación de
          recursos públicos, al logro de resultados, con base en el desempeño
          observado y esperado del ejercicio de los recursos presupuestarios, en
          apego a los objetivos de planeación de cada uno de los niveles de
          gobierno (Federal, Estatal y Municipal).
        </li>
        <li>
          Su aplicación se encuentra estrechamente vinculada con la contabilidad
          gubernamental y permite generar indicadores de resultados y de
          desempeño; y conectarlos con la información financiera, contribuyendo
          así a cumplir con los principios de administración de recursos
          establecidos en el artículo 134 de la Constitución Política de los
          Estados Unidos Mexicanos.
        </li>
      </ul>
    </div>
    <img
      src="@/assets/img/ciclo-presupuestario.jpg"
      class="d-block w-100"
      alt="..."
    />
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>