<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          La comunicación de resultados, un problema añejo.
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">La comunicación de resultados, un problema añejo.</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/communication.jpeg"
          class="w-100 my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
          Lo de hoy es escuchar a gran cantidad de directivos públicos afirmar
          que todo iría mejor si su equipo de comunicación social fuera más
          inteligente para enterar a la sociedad de logros en la gestión diaria.
          Me atrevo a señalar que en muchos casos se califica esta situación
          como el mayor problema para alcanzar un mejor desempeño gubernamental.
        </p>
        <p>
          El problema no es de hoy, baste decir que la comunicación
          gubernamental como la entendemos en la actualidad es una invención de
          los romanos, particularmente del emperador Julio Cesar, quien fue el
          creador del primer periódico del que tenemos antecedente; se trató del
          <em>Acta Diurna Populi Romani</em>. Escribe Suetonio: “Lo primero que
          ordenó, al posesionarse de su dignidad, fue que se llevara un diario
          de todos los actos populares y del senado y que se publicase”. (De
          Masi:2001).
        </p>
        <p>
          El hecho de que el Acta Diurna se publicará todos los días en los
          muros de la ciudad para consumo de los ciudadanos romanos, sitúa a la
          comunicación como una función antigua.
        </p>
        <p>
          <b> Si la tradición es añeja, ¿Por qué no lo hacemos bien? </b>
        </p>
        <p>
          Una de las tradiciones de la burocracia es el orden y el cumplimiento
          sistemático de tareas. A la fecha existen una gran cantidad de
          personas servidoras públicas que son capaces de cumplir cabalmente con
          su trabajo, inclusive cuando va de por medio el acatamiento de un
          exceso de normas y procedimientos propios de su función, al grado de
          que tienen resultados y evaluaciones muy satisfactorias por parte de
          sus superiores, dicho esto, el problema radica en que en los tiempos
          recientes de alta exposición mediática, la comunicación de resultados
          debiera ser parte del proceso de trabajo normal en la administración
          pública.
        </p>
        <p>
          Digamos entonces que comunicar los resultados del trabajo cotidiano es
          un requisito más de los múltiples requisitos a cumplir, lo que me
          permite afirmar que la catástrofe de hoy, se puede convertir en un
          buen trabajo con la debida capacitación. En otras palabras, si no se
          ha hecho bien es porque anteriormente no era necesario.
        </p>
        <p>
          Mas que tachar a la comunicación pública de: mala, pésima, discordante
          o tardía, me atrevo a señalar, como lo hace la especialista en
          comunicación de la administración pública, María José Canel, que hoy
          en día la comunicación gubernamental solo es: <b>insuficiente.</b>
        </p>
        <p>
          Definir prioridades de la gestión, atender audiencias, comunicar en
          tiempo real, tener un conjunto de programas insignia y modelar un
          sistema de monitoreo de los resultados se dice fácil, pero cada
          institución no tiene las mismas circunstancias, por lo que no debe
          emplear los mismos argumentos comunicativos. Hay que hacer
          diagnósticos específicos que nos arrojen la mejor manera de comunicar
          desde la administración pública.
        </p>
        <p>
          Desestimemos también aquellos argumentos que señalan a problemas de
          desprestigio y errores de la clase política como imposibles de atender
          en la comunicación pública, pues hay herramientas como la Gestión
          basada en Resultados (GbR) que vuelven operativos y atendibles una
          gran cantidad problemas (no todos), que de primera mano y sin un
          análisis adecuado se consignan en el radar de responsabilidad de una
          pésima operación política.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>