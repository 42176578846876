<template>
  <div class="container-fluid">
    <img
      src="@/assets/img/calendario-pae-2024.jpg"
      class="d-block w-100"
      alt="..."
    />
  </div>
</template>

<script>
export default {
  name: "CalendarioView",
};
</script>

<style>
</style>