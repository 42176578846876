<template>
  <div class="container-fluid">
    <h1 class="my-h1">Preguntas frecuentes</h1>
    <h2 class="my-h2">
      ¿Principal objetivo del Presupuesto basado en Resultados?
    </h2>
    <div class="general-text">
      Es el cumplimiento del artículo 134 de la Constitución Política de los
      Estados Unidos Mexicanos, misma que establece que los recursos públicos
      sean administrados con eficiencia, eficacia, economía, transparencia y
      honradez, y que los resultados del ejercicio de dichos recursos sean
      evaluados por las instancias técnicas que se establezcan.
    </div>
    <h2 class="my-h2">
      ¿Cuáles son las ventajas del Presupuesto basado en Resultados?
    </h2>
    <div class="general-text">
      <ul>
        <li>Facilitar la utilización adecuada de los recursos.</li>
        <li>Proporcionar eficiencia en las operaciones.</li>
        <li>
          Ayudar a lograr una buena planeación de los entes gubernamentales,
        </li>
        <li>
          Mostrar los resultados que se obtendrán de poner en práctica los
          planes.
        </li>
        <li>
          Principal herramienta que les permite a las personas saber cuáles son
          sus ingresos y gastos en un tiempo determinado.
        </li>
      </ul>
    </div>
    <h2 class="my-h2">
      ¿Cuál es la importancia del Presupuesto basado en Resultados?
    </h2>
    <div class="general-text">
      Conocer cuánto puede destinar al ahorro y prevenir y corregir errores
      financieros.
    </div>
    <h2 class="my-h2">
      ¿Cuáles son los beneficios del Presupuesto basado Resultados para la
      gestión local?
    </h2>
    <div class="general-text">
      <ul>
        <li>
          Las diferentes Entidades podrán mejorar la elección del gasto a
          priorizar.
        </li>
        <li>
          Orienta a las Entidades a que los gastos sean más eficientes y
          eficaces, por lo tanto, mejoraran su desempeño en la asignación
          presupuestaria y el desarrollo de las capacidades individuales y
          colectivas de los distintos actores sociales que participan en el
          proceso.
        </li>
      </ul>
    </div>
    <h2 class="my-h2">
      ¿Cuál es el ciclo del Presupuesto basado en Resultados?
    </h2>
    <div class="general-text">
      Son los actos y contratos expedidos o celebrados por los órganos públicos
      en desarrollo de la capacidad de contratar y de comprometer el Presupuesto
      basado en Resultados realizados en cumplimiento de las funciones públicas
      asignadas por la Ley.
    </div>
    <h2 class="my-h2">
      ¿Cuáles son los principios fundamentales del Presupuesto basado en
      Resultados?
    </h2>
    <div class="general-text">
      <ul>
        <li>
          Vincular el presupuesto a los objetivos de desarrollo sostenible de la
          Agenda 2030.
        </li>
        <li>
          Vincular el presupuesto a las políticas públicas del plan nacional y
          estatal.
        </li>
        <li>Incorporar la Matriz de Indicadores para Resultados (MIR).</li>
        <li>Impulsar la evaluación del desempeño.</li>
        <li>
          Asignar recursos considerando la evaluación de los resultados
          alcanzados.
        </li>
        <li>Generar mayor transparencia y rendición de cuentas.</li>
      </ul>
    </div>
    <h2 class="my-h2">
      ¿Qué miden los elementos del Presupuesto basado en Resultados?
    </h2>
    <div class="general-text">
      El impacto de la ejecución de los programas presupuestarios y de la
      aplicación de los recursos asignados a estos, con el objeto de entregar
      mejores bienes.
    </div>
    <h2 class="my-h2">Servicio de Implantación:</h2>
    <div class="general-text">
      Para mejorar la forma en que los gobiernos administran sus recursos se
      debe de dar el Presupuesto basado en Resultados con el fin de consolidar
      su desarrollo, social, económico y disponer de mejores servicios a la
      población.
      <br />
      <br />
      Los trabajos que efectuamos son los siguientes:
      <br />
      <ol type="1">
        <li>Planeación del PbR</li>
        <ul class="ul-clean">
          <li>1.1. Adecuación del marco normativo de gestión del PbR.</li>
          <li>1.2. Diagnóstico y alineamiento del PbR.</li>
          <li>1.3. Metodología de Marco Lógico.</li>
        </ul>

        <li>Programación</li>
        <ul class="ul-clean">
          <li>2.1. Incorporación del PbR al Ciclo Presupuestario.</li>
        </ul>

        <li>Presupuestación</li>
        <ul class="ul-clean">
          <li>3.1. Presupuestación del PbR.</li>
        </ul>

        <li>Seguimiento y evaluación del PbR</li>
        <ul class="ul-clean">
          <li>4.1. Monitoreo del PbR.</li>
          <li>4.2. Evaluación de la Gestión bajo el enfoque del PbR</li>
        </ul>

        <li>Comunicación de Resultados del PbR</li>
        <ul class="ul-clean">
          <li>5.1. Programa de Comunicación de Resultados del PbR.</li>
        </ul>

        <li>Consolidación del PbR</li>
        <ul class="ul-clean">
          <li>6.1. Certificación de Competencias.</li>
        </ul>
      </ol>
    </div>
  </div>
</template>
  
<script>
export default {};
</script>
  
  <style>
</style>