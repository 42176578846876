
<template>
  <div class="container-fluid">
    <!--Table 3-->
    <div class="col-12 table-title">
      Nombre del Taller: Diseño de Convenio para la Mejora de Resultados
      Gubernamentales
    </div>
    <div class="col-12 table-white">
      <b>Objetivo:</b>
      <ul>
        <li>
          Formar agentes de cambio con competencias en el diseño e
          implementación de herramientas que contribuyan a la mejora y al
          perfeccionamiento de la política pública en todos los niveles de
          gobierno.
        </li>
      </ul>
    </div>
    <div class="col-12 table-blue">
      <b>Descripción:</b><br />
      El taller promoverá en los participantes el fortalecimiento de las
      capacidades en vistas a mejorar la gestión y participación de los
      programas y proyectos de las áreas a las cuales pertenecen, ya que todo
      agente de la administración pública debe tener una sólida formación en
      técnicas y herramientas que le permitan asumir con idoneidad y
      responsabilidad las tareas encomendadas en los diferentes ámbitos en el
      que se desarrollará profesionalmente.
    </div>
    <div class="col-12 table-white">
      <b>Características:</b><br />
      Dirigido a: Profesionistas, independientes, consultores, académicos,
      estudiantes, titulares de las Unidades de Información, Planeación,
      Programación y Evaluación (UIPPE), similares de los entes gubernamentales
      federales, estatales o municipales, o personal designado interesado en
      conocer herramientas que les permitan mejorar las políticas públicas.
      <br /><br />

      Garantía de satisfacción: Avalamos la transmisión de conocimientos a
      través der una metodología que permita elegir la información para el
      diseño de productos o resultados.<br /><br />

      Constancia: Al término del curso se otorgará una constancia de
      participación con valor curricular, con la insignia de Visión y Estrategia.
    </div>
    <div class="col-12 table-blue">
      <b>Temario:</b>
      <ol type="1">
        <li>Características del título del convenio.</li>
        <li>
          Elementos para considerar en los antecedentes del convenio de mejora.
        </li>
        <li>Particularidades del apartado de declaraciones del convenio.</li>
        <li>Definición de cláusulas y detallar sus apartados.</li>
        <ul class="ul-clean">
          <li>4.1. Objetivo.</li>
          <li>4.2. Compromisos y responsabilidades.</li>
          <li>4.3. Responsables.</li>
          <li>4.4. Formatos autorizados (hallazgos, recomendaciones).</li>
          <li>4.5. Cumplimiento.</li>
          <li>4.6. Incumplimiento.</li>
          <li>4.7. Prórroga.</li>
          <li>4.8. Publicidad.</li>
          <li>4.9. Vigencia.</li>
          <li>4.10. Interpretación.</li>
        </ul>
        <li>Peculiaridades del apartado de firmas.</li>
      </ol>
    </div>
    <div class="col-12 table-white">
      <b>Horas de duración:</b>1 día (5 horas)
    </div>
    <div class="col-12 table-blue">
      <b>Fecha de realización: </b>29 de mayo de 2023
    </div>
    <!--Table 3 end-->
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  
  