<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Gastar bien, la meta de los gobiernos municipales del siglo XXI.
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Gastar bien, la meta de los gobiernos municipales del siglo XXI.</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/money.jpeg"
          class="w-100 my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align:end">
                Ramón Cuevas Martínez
              </p>
              <p style="text-align:center">
                <em>
                  Para lo limpio, ni jabón se ocupa. Frase popular del norte de México.
                </em>
              </p>
              <p>
                Para gastar mejor, cualquier gobierno tiene que ser capaz de realizar un proceso con rigor técnico, normativo y metodológico que permita la mejora continua de sus programas presupuestarios y/o fondos federales ejercidos. El rigor técnico y metodológico se cumple cuando se acatan las disposiciones emitidas por el Consejo Nacional de Evaluación de la Política de Desarrollo Social (CONEVAL).
              </p>
              <p>
                Los diputados de las pasadas legislaturas han hecho su tarea, regalaron a la ciudadanía una amplia base normativa para exigir que los gobiernos gasten mejor, por ejemplo, la máxima disposición de este país, es decir, la Constitución Política de los Estados Unidos Mexicanos, en el artículo 134 establece que “los recursos públicos deben ser administrados con eficiencia, eficacia, transparencia y honradez; y a su vez, deben ser evaluados”. 
              </p>
              <p>
                Si bien la integración y ejecución del Programa Anual de Evaluación (PAE) es el punto central para cumplir con las disposiciones normativas, es igual de importante que se cumpla con su publicación, para conocimiento de todas las partes interesadas en el buen funcionamiento gubernamental. Iniciar con la publicación del PAE representa un acierto en transparencia, rendición de cuentas, datos abiertos y acceso a la información, además de que sustancia el cumplimiento del artículo 85 de la Ley Federal de Presupuesto y Responsabilidad Hacendaria, donde se establece que “los documentos de resultados y evaluación del ejercicio de recursos federales deben ser de fácil acceso a la ciudadanía“; de igual manera, en el artículo 79 de la Ley General de Contabilidad Gubernamental se establece que “los entes públicos (ayuntamientos) deberán publicar es las páginas oficiales de su municipio, su Programa Anual de Evaluación, incluyendo: metodologías, indicadores, resultados y convenio de mejora”.
              </p>
              <p>
                Durante tres años, el Índice de Información para la Evaluación Presupuestara Municipal (IIEPM) 2021 se ha consolidado como una herramienta encargada de validar que los 125 municipios del Estado de México cumplan con el proceso de integración, ejecución y publicación de su Programa Anual de Evaluación.
              </p>
              <p>
                Siguiendo con el sustento normativo del PAE, el IIEPM valida su integración a través de la información disponible en los sitios web oficiales de los ayuntamientos; es decir que, si esta no se encuentra publicada, no hay forma de validar que se haya realizado el ejercicio de evaluación. De acuerdo con la metodología del IIEPM 2021, los municipios cuya información no es encontrada en las páginas web son catalogados como “Desobligados”, pues se incumple con la normatividad señalada
              </p>
              <p>
                Recomendaciones para la publicación del PAE en el sitio web oficial:
                <ol>
                  <li>Verificar que todos los enlaces estén habilitados y contengan la información especificada.</li>
                  <li>Ordenar y clasificar los documentos referentes al ejercicio de evaluación en una sola ventana. (PAE, TdR, resultados de la evaluación y convenio de mejora).</li>
                  <li>Información disponible en la sección de CONAC del ayuntamiento.</li>
                  <li>Colocar, en la página principal del ayuntamiento, un enlace directo a toda la información del Programa Anual de Evaluación. </li>
                </ol>              
              </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>