<template>
  <div class="container-fluid">
    <h1 class="my-h1">En que consiste</h1>
    <div class="general-text">
      Es un estándar de competencia que hemos desarrollado a partir de la
      metodología de Gestión para Resultados (GpR), tiene como propósito servir
      como referente para la capacitación y evaluación de las personas que se
      desempeñan en el proceso de administración gubernamental por resultados
      dentro de la Administración Pública Federal, Estatal o Municipal, que
      ayudan a gestionar los Resultados a través de facilitar a las
      organizaciones públicas la dirección efectiva e integrada de su proceso de
      creación de valor público.
    </div>
    <h2 class="my-h2">Propósito</h2>
    <div class="general-text">
      Servir como referente para la capacitación y evaluación de las personas
      que se desempeñan en el proceso de Administración Gubernamental por
      Resultados (AGpR) dentro de la Administración Pública Federal, Estatal o
      Municipal, que ayudan a gestionar los Resultados a través de facilitar a
      las organizaciones públicas la dirección efectiva e integrada de su
      proceso de creación de valor público. <br /><br />
      Asimismo, puede ser referente para el desarrollo de programas de
      capacitación y de formación basados en Estándares de Competencia (EC).<br /><br />
      El presente EC se refiere únicamente a funciones para cuya realización no
      se requiere por disposición legal, la posesión de un título profesional.
      Por lo que para certificarse en este EC no deberá ser requisito el poseer
      dicho documento académico.<br /><br />
      Para alcanzar la competencia en este Estándar de Competencia, se requieren
      en promedio 24 horas de capacitación.
    </div>
    <h2 class="my-h2">Descripción general</h2>
    <div class="general-text">
      El estándar de competencia describe las funciones propias para constituir
      una Administración por Resultados, que desarrollen las y los funcionarios,
      a través de la construcción de objetivos, incorporando un mejor uso de
      estrategias, riesgos, indicadores y metas. Es decir, que satisfaga las
      necesidades de la ciudadanía incorporando mecanismos de competencia que
      promuevan los servicios de calidad, mediante nuevos modelos de
      organización gubernamental. Asimismo, establece la necesidad de efectuar
      una comunicación efectiva de los resultados obtenidos.<br /><br />
      La persona que se evalúe demostrará que tiene la habilidad para
      desarrollar una cultura de monitoreo y evaluación que fortalezca la
      incorporación del enfoque de Administración Gubernamental por Resultados
      en las instituciones públicas, a través de la planeación, seguimiento,
      evaluación y comunicación de una gestión pública de calidad, orientada a
      resultados y centrada en la ciudadanía y/o usuarios de los programas. La
      persona que se evalúe demostrará sus conocimientos para la elaboración,
      planeación, evaluación y seguimiento de planes estratégicos, planes
      operativos, riesgos, proyectos y procesos; y desde su formulación más
      temprana, permitan efectuar un monitoreo y evaluación durante y con
      posterioridad a su implementación, así como la permanencia y planeación de
      los procesos de comunicación de tales resultados.<br /><br />
      También establece los conocimientos teóricos -básicos y prácticos- con los
      que debe contar para realizar un trabajo, así como las actitudes
      relevantes en su desempeño.<br /><br />
      El presente EC se fundamenta en criterios rectores de legalidad,
      competitividad, libre acceso, respeto, trabajo digno y responsabilidad
      social.
    </div>
    <h2 class="my-h2">
      Perfil del Estándar de Competencia en Administración Gubernamental por
      Resultados (AGpR) ECGAGR01
    </h2>

    <div class="row table-row">
      <div class="col-5 table-title">Estándar de Competencia</div>
      <div class="col-7 table-title">Elemento</div>
      <div class="w-100"></div>
      <div class="col-5 table-gray table-center">
        Administración Gubernamental por Resultados
      </div>
      <div class="col-7 table-gray">
        <b>Elemento 1 de 4</b><br />Planear el Sistema de Administración por
        Resultados<br /><br />
        <b>Elemento 2 de 4</b><br />Seguimiento a los Indicadores
        Estratégicos<br /><br />
        <b>Elemento 3 de 4</b><br />Evaluar los Indicadores del Plan de
        Gobierno<br /><br />
        <b>Elemento 4 de 4</b><br />Comunicar los Resultados
      </div>
    </div>
    <br />
    <!--download text-->
    <div class="mb-3" style="text-align: center">
      <a
        class="download-text"
        href="/pptx/Estandar de Competencia, AGpR.pptx"
        target="_blank"
      >
        Descargar aquí la presentación del Estándar
        <i class="fa fa-cloud-download download-text"></i>
      </a>
    </div>
    <!--download text end-->
  </div>
</template>

<script>
export default {
  name: "EnQueConsisteView",
};
</script>

<style>
</style>