<template>
  <div class="container-fluid">
    <h1 class="my-h1">Información general</h1>
    <h2 class="my-h2">Antecedentes</h2>
    <div class="general-text">
      El 11 de agosto de 2016 el Comité de Gestión por Competencias de la
      empresa Visión y Estrategia Consultoría, integrado por un Grupo Técnico de Expertos
      en la función aprobó el Estándar de Competencia en Administración
      Gubernamental por Resultados AGpR. ECGAGR01, cuyo un periodo de revisión y
      actualización fue de tres años, mismo tiempo de vigencia del certificado
      de competencia en el Estándar en mención. <br /><br />
      El Comité de Gestión por Competencias surge de las diversas acciones del
      Sistema Nacional de Competencias (SNC), generadora de valor para los
      trabajadores y empleadores de México. El SNC es un instrumento del
      Gobierno Federal que contribuye a la competitividad económica, al
      desarrollo educativo y al progreso social de México, con base en el
      fortalecimiento de las competencias de las personas.<br /><br />
      El Sistema Nacional de Competencias facilita los mecanismos para que
      organizaciones e instituciones públicas y privadas, cuenten con personas
      más competentes.
    </div>
    <h2 class="my-h2">
      Datos generales del Estándar de Competencia en Administración
      Gubernamental por Resultados (AGpR) ECGAGR01
    </h2>
    <div class="row table-row">
      <div class="col-5 table-title">Código</div>
      <div class="col-7 table-title">Título</div>
      <div class="w-100"></div>
      <div class="col-5 table-gray table-center">ECGAGR01</div>
      <div class="col-7 table-gray">
        Administración Gubernamental por Resultados
      </div>
    </div>
    <br />
    <h2 class="my-h2">
      Beneficios que se obtienen con la implantación del Estándar de Competencia
      en Administración Gubernamental por Resultados (AGpR) ECGAGR01
    </h2>
    <div class="row table-row">
      <div class="col-12 table-title">
        Para los servidores públicos certificados
      </div>
      <div class="col-12 table-gray">
        <ul>
          <li>
            Capacidad para desarrollar una cultura de monitoreo y evaluación de
            programas.
          </li>
          <li>
            Habilidad para plantear proyectos que conduzcan a buenos resultados.
          </li>
          <li>
            Aprenderán a elaborar, planear evaluar y dar seguimiento planes
            estratégicos, planes operativos, riesgos, proyectos y procesos.
          </li>
          <li>Capacidad de comunicar adecuadamente los resultados logrados.</li>
          <li>Reconocimiento social de sus competencias laborales.</li>
          <li><b> Disminución de tiempo en la búsqueda de empleo.</b></li>
          <li>
            <b>
              Orientación para continuar a lo largo de la vida, el aprendizaje
              permanente.
            </b>
          </li>
          <li>
            <b>
              Contar con mayores elementos para la empleabilidad, estabilidad y
              promoción laboral.
            </b>
          </li>
          <li>
            <b> Satisfacción y motivación con el trabajo realizado.</b>
          </li>
          <li>
            <b>
              Mayores posibilidades de ingreso y/o movilidad en el mercado
              laboral.
            </b>
          </li>
        </ul>
      </div>
    </div>
    <div class="row table-row">
      <div class="col-12 table-title">
        Para la administración pública y el gobierno local (empleadores)
      </div>
      <div class="col-12 table-gray">
        <ul>
          <li>
            Contarán con programas de trabajo integrados a partir de la
            construcción de objetivos enfocados a resultados, incorporando un
            mejor uso de estrategias, riesgos, indicadores y metas.
          </li>
          <li>
            Podrán comunicar adecuadamente cada una de las partes del programa,
            a partir de una cadena de valor.
          </li>
          <li>
            Estarán en posibilidades de aprovechar la metodología de Gestión
            para Resultados (GpR), en la integración de sus planes estratégicos,
            planes operativos, riesgos, proyectos y procesos; desde su
            formulación más temprana, durante su implementación, así como en la
            permanencia y planeación de los procesos de comunicación de tales
            resultados.
          </li>
          <li>
            Asociación con procesos más modernos de organización del trabajo.
          </li>
          <li>
            <b
              >Mejora continua, dado que la certificación está entrelazada con
              las políticas de productividad y competitividad laboral.</b
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="row table-row">
      <div class="col-12 table-title">Para las partes interesadas</div>
      <div class="col-12 table-gray">
        Las personas, organizaciones y asociaciones que estén interesadas en el
        buen desempeño gubernamental, encontrarán de modelo de innovación
        organizacional, que refleja el valor agregado en los bienes, servicios y
        regulaciones, se enfoca a hacer análisis apropiados y entiende la
        necesidad de planear, no solo de cumplir, sino para lograr los impactos
        esperados en los programas gubernamentales.
      </div>
    </div>
    <br />
    <h2 class="my-h2">
      Nivel del Sistema Nacional de Competencias que se ocupa en este Estándar
      de Competencia en Administración Gubernamental por Resultados (AGpR)
      ECGAGR01
    </h2>
    <div class="general-text">
      <b>Nivel dos: estratégico</b> <br />
      Integrado por el Comité de Gestión por Competencias quien define los
      estándares de competencia de las personas y las soluciones de evaluación y
      certificación. Desempeña actividades programadas que, en su mayoría, son
      rutinarias y predecibles. Depende de las instrucciones de un superior. Se
      coordina con compañeros de trabajo del mismo nivel jerárquico.
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>