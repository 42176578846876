<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Resultados de la Evaluación del Programa federal “Sembrando Vida”, para los años 2019 y 2020
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Resultados de la Evaluación del Programa federal “Sembrando Vida”, para los años 2019 y 2020</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/results2.jpeg"
          class="w-100 my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
                En días pasados CONEVAL dio a conocer los resultados de la evaluación de uno de los programas insignia del gobierno federal bajo la responsabilidad de la Secretaría del Bienestar, y que tiene por objetivo Lograr que los campesinos de zonas rurales mejoren sus ingresos para hacer productiva la tierra, generando empleos, autosuficiencia alimentaria, mejora en los ingresos de la población y recuperación de la cobertura forestal. La población objetivo es de campesinos mayores de edad, que habitan en localidades rurales en municipios con niveles de rezago social y que son propietarios o poseedores de 2.5 hectáreas disponibles para proyectos agroforestales.
              </p>
              <p>
                El apoyo que reciben es $5,000.00, más apoyos en especie (plantas, insumos, herramientas) y acompañamiento técnico. 
              </p>
              <p>
                El presupuesto ejercido en 2019 fue de 15,000 millones de pesos. Este año crecerá en un 90% para llegar a 28,504.9 millones de pesos, y tendrá una cobertura de 20 entidades federativas. Para dimensionar el tamaño de los recursos destinados hay que señalar que ejercerá ¾ partes del presupuesto total que los 125 municipios mexiquenses tuvieron en 2019.
              </p>
              <p>
                Entre los aspectos críticos que marcan el inicio de un programa muy problemático, se encuentran:
                <ol>
                  <li>La definición del problema no señala claramente qué se busca resolver con el programa, es decir, si los recursos son solo un ingreso para los campesinos o se enfoca a lograr mayor productividad de la tierra. </li>
                  <li>La MIR 2020 del Programa no cuenta con indicadores estratégicos que den cuenta de la contribución al desarrollo sostenible, es decir, no demuestran sí se generaron empleos, sí se disminuyeron las carencias de acceso a una alimentación sana y nutritiva, sí se mejora el ingreso, o sí se incrementó la sostenibilidad del territorio reforestado. </li>
                  <li>El programa tuvo un aumento de recursos de 13 mil millones en el año 2020 respecto al año 2019, es decir, un aumento del 90% en términos nominales. Esto no se vio reflejado en la cobertura, ya que para el 2019 apoyó a 230 mil campesinos, y para el 2020, solo 170 mil, disminuyendo un 26%. Asimismo, para el 2019 se reforestarían 575 mil hectáreas, mientras que para el 2020, solo 425 mil hectáreas, una disminución de 150 mil hectáreas, cuando debió ser a la inversa, aumentar la cobertura dado el aumento presupuestal. </li>
                  <li>Hay programas con objetivos y funciones similares en dependencias como la SEMARNAT y el SADER. </li>
                  <li>Los calendarios para la entrega de las plantas no son acordes a los ciclos agrícolas productivos, lo que da como resultado un alto porcentaje de mortandad de la planta, representando mayor gasto en este componente.</li>
                  <li>El padrón de sujetos de derechos (beneficiarios), no está sistematizado y no cuenta con procedimientos de actualización, depuración e integración. </li>
                  <li>No cuenta con procesos clave de operación, ni un manual de procedimientos de aplicación homogénea en las diferentes regiones.</li>
                  <li>No cuenta con mecanismos para informar a los solicitantes que no fueron aceptados las causas de su no incorporación.</li>
                  <li>Sobre el seguimiento de los beneficiarios, no cuenta con mecanismos de supervisión cruzada que permitan obtener información y triangular el seguimiento de la implementación del programa en los territorios.</li>
                  <li>No cuenta con mecanismos para hacer el cruce de padrones entre programas y los criterios y procedimientos para dar de baja a quienes reciban más de un apoyo. </li>
                  <li>El personal que opera el programa cuenta con cargas excesivas de trabajo, no cuenta con espacios de trabajo, ni de almacenaje, no cuenta con equipamiento, ni seguro médico, ni recursos suficientes para el cumplimiento de sus funciones.</li>
                </ol>
              </p>
              <p>
                No cabe duda que #EvaluarSirve. Felicidades a CONEVAL por presentar adecuadamente la evidencia del fracaso de Sembrado Vida. Los resultados de la evaluación deben gestionarse adecuadamente para obtener mejores resultados este año, ahí radica la importancia de estos atinados ejercicios.
              </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>