
<template>
  <div class="container-fluid">
    <!--Table 4-->
    <div class="col-12 table-title">
      Nombre del Curso: Desarrollo integral del Programa Anual de Evaluación
    </div>
    <div class="col-12 table-white">
      <b>Objetivo:</b>
      <ul>
        <li>
          Identificar todos los elementos o aspectos que se requieren para
          elaborar un programa anual de evaluación y las características para
          dar cumplimiento a este en la temporalidad establecida en él.
        </li>
      </ul>
    </div>
    <div class="col-12 table-blue">
      <b>Descripción:</b><br />
      Al finalizar el curso los participantes desarrollarán habilidades para
      diseñar un programa anual de evaluación, y todas las herramientas que se
      requieren en su conjunto para el cumplimiento de este, tales como: los
      términos de referencia, como elaborar y obtener un informe de resultados,
      y convenio de mejora.
    </div>
    <div class="col-12 table-white">
      <b>Características:</b><br />
      Dirigido a: Profesionistas, independientes, consultores, académicos,
      estudiantes, titulares de las Unidades de Información, Planeación,
      Programación y Evaluación (UIPPE), similares de los entes gubernamentales
      federales, estatales o municipales, o personal designado interesado la
      estructura de un programa anual de evaluación y los componentes que lo
      integran para su cumplimiento.
      <br /><br />
      Garantía de satisfacción: Avalamos la transmisión de conocimientos a
      través der una metodología que permita elegir la información para el
      diseño de productos o resultados.
      <br />
      <br />
      Constancia: Al término del curso se otorgará una constancia de
      participación con valor curricular, con la insignia de Visión y Estrategia.
    </div>
    <div class="col-12 table-blue">
      <b>Temario:</b>
      <ol>
        <li>
          Programa Anual de Evaluación.
          <ul class="ul-clean">
            <li>1.1. Marco teórico y conceptual.</li>
            <li>
              1.2. Consideraciones generales.
              <ul class="ul-clean">
                <li>1.2.1. Objetivo general.</li>
                <li>1.2.2. Objetivos específicos.</li>
              </ul>
            </li>
            <li>1.3. Glosario.</li>
            <li>1.4. Diagnóstico de programas.</li>
            <li>1.5. Monitoreo.</li>
            <li>
              1.6. Proceso de evaluación.
              <ul class="ul-clean">
                <li>1.6.1. Coordinación.</li>
                <li>1.6.2. Contratación.</li>
                <li>1.6.3. Presupuesto.</li>
                <li>1.6.4. Difusión.</li>
              </ul>
            </li>
            <li>1.7. Valoración de programas presupuestarios.</li>
            <li>
              1.8. Uso de información del desempeño.
              <ul class="ul-clean">
                <li>
                  1.8.1. Información estratégica para la toma de decisiones.
                </li>
                <li>1.8.2. Aspectos Susceptibles de Mejora.</li>
              </ul>
            </li>
            <li>1.9. Consideraciones finales.</li>
            <li>1.10. Cronograma de ejecución.</li>
            <li>1.11. Transitorios.</li>
            <li>1.12. Anexos.</li>
          </ul>
        </li>
        <li>
          Términos de Referencia.
          <ul class="ul-clean">
            <li>2.1. Justificación.</li>
            <li>2.2. Objetivo general y específicos.</li>
            <li>
              2.3. Alcances de los servicios.
              <ul class="ul-clean">
                <li>
                  2.3.1. Metodología o forma como se prestará el servicio.
                </li>
                <li>2.3.2. Actividades para realizar.</li>
                <li>2.3.3. Productos.</li>
                <li>2.3.4. Informes para entregar.</li>
              </ul>
            </li>
            <li>2.4. Perfil de la empresa o consultor.</li>
            <li>
              2.5. Condiciones contractuales.
              <ul class="ul-clean">
                <li>2.5.1. Oferta económica.</li>
                <li>2.5.2. Forma de pago.</li>
                <li>2.5.3. Plazo para la entrega del producto.</li>
                <li>2.5.4. Supervisión.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Características del título del convenio.
          <ul class="ul-clean">
            <li>
              3.1. Elementos para considerar en los antecedentes del convenio de
              mejora.
            </li>
            <li>3.2. del apartado de declaraciones del convenio.</li>
            <li>
              3.3. Definición de cláusulas y detallar sus apartados.
              <ul class="ul-clean">
                <li>3.3.1. Objetivo.</li>
                <li>3.3.2. Compromisos y responsabilidades.</li>
                <li>3.3.3. Responsables.</li>
                <li>
                  3.3.4. Formatos autorizados (hallazgos, recomendaciones).
                </li>
                <li>3.3.5. Cumplimiento.</li>
                <li>3.3.6. Incumplimiento.</li>
                <li>3.3.7. Prórroga.</li>
                <li>3.3.8. Publicidad.</li>
                <li>3.3.9. Vigencia.</li>
                <li>3.3.10. Interpretación.</li>
              </ul>
            </li>
            <li>3.4. Peculiaridades del apartado de firmas.</li>
          </ul>
        </li>
      </ol>
    </div>
    <div class="col-12 table-white">
      <b>Horas de duración: </b>4 días 16 horas (4 horas por día)
    </div>
    <div class="col-12 table-blue">
      <b>Fecha de realización: </b>30 y 31 de mayo, 1 y 2 de junio de 2023
    </div>
    <!--Table 4 end-->
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  
  