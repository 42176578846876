<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Medir la pobreza: Lo que no se mide se deteriora.
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Medir la pobreza: Lo que no se mide se deteriora.</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/poverty.jpeg"
          class="w-100 my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
          <em>
            “La pobreza a menudo priva al hombre de toda virtud: es difícil que
            un costal vacío se mantenga derecho”. Benjamin Franklin
          </em>
        </p>
        <p>
          Para conocer el estado de la pobreza el Consejo Nacional de Evaluación
          de la Política de Desarrollo Social (CONEVAL), ha establecido una
          metodología que considera al menos 8 indicadores y vincula la
          evaluación integral de la política nacional de desarrollo social para
          efectuar su medición.
        </p>
        <p>
          La evaluación de programas (Políticas públicas) verifica en qué medida
          el diseño, métodos de aplicación y ejecución son apropiados para
          mejorar a los mismos, también monitorea de forma objetiva los
          resultados de las acciones públicas para fortalecer y consolidar el
          quehacer gubernamental, sin dejar a un lado la pertinencia, eficiencia
          e impacto que tienen, es decir, mejora la gestión y maximiza su
          utilidad.
        </p>
        <p>
          Entre 2008 y 2018, el número de personas en situación de pobreza pasó
          de 49.5 a 52.4 millones, y se podría decir que es una buena cifra
          considerando que el porcentaje de la población total en situación de
          pobreza pasó de 44.4% a 41.9% entre esos años, lo cual representa una
          disminución anual media de 0.24%, tomando en cuenta el crecimiento
          poblacional natural. También se tiene información oficial (No otros
          datos) de que el número de mexicanos en situación de pobreza extrema
          pasó de 12.3 a 9.3 millones. No íbamos bien, pero tampoco tan mal.
        </p>
        <p>
          El número actual de personas en pobreza según el CONEVAL (2021), es de
          55 millones 700 mil personas, en los últimos tres años (2018, 2019 y
          2020) 3 millones 800 mil mexicanos se sumaron a esta triste realidad.
          La pobreza extrema aumentó 2.1 millones y la pobreza moderada se elevó
          a 1.7 millones de personas.
        </p>
        <p>
          <b>
            En 10 años, 2.9 millones de personas pasaron a situación de pobreza
            y en los últimos tres años 3.8 millones se sumaron a la pobreza.
          </b>
        </p>
        <p>
          ¿Por dónde empezar para revertir esta tragedia? Para empezar, es
          indispensable evaluar los programas de desarrollo social. En 2018, el
          gobierno federal consideró, en el Programa Anual de Evaluación, 63
          evaluaciones a programas federales de diseño, consistencia y
          resultados, procesos, específicas y estratégicas, también se evaluaron
          aleatoriamente los fondos de aportaciones federales en el ámbito
          federal. Para 2019, se realizaron 132 evaluaciones, más fondos
          federales, y en 2020, 149 evaluaciones de programas más fondos
          federales. Lo grave es qué, por los resultados mostrados por CONEVAL,
          la mayoría de las evaluaciones no se convirtieron en planes de acción,
          perdiéndose la oportunidad de mejora el desempeño gubernamental.
        </p>
        <p>
          Monitorear el éxito de todos los recursos destinados a programas
          sociales es el único camino científico para no seguir fabricando
          pobres. De acuerdo con el CONEVAL (2021), la pobreza va en un aumento
          del 7.3% en lo que va del actual sexenio.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>