<template>
  <div class="container-fluid">
    <!--Animated title-->
    <div class="animate-div">
      <p class="animate__animated animate__slideInDown">Nosotros</p>
    </div>
    <div class="row mb-5 mt-1">
      <div class="col-lg-6 py-2">
        <!--card MISIÓN-->
        <div class="card">
          <div class="card-header card-header-nosotros">
            <h5 class="card-title">Misión</h5>
          </div>
          <div class="card-body card-body-nosotros">
            <p class="card-text">
              Somos una Organización que ofrece servicios profesionales con
              personal altamente competente, que promueve entre nuestros
              clientes y partes interesadas un ambiente de confianza; con
              precios competitivos, equitativos y atractivos conforme a la
              dinámica del mercado, tenemos amplia capacidad para dar
              certidumbre en la ejecución de nuestras evaluaciones; entregamos
              oportunamente los informes de evaluación a las instituciones.
            </p>
          </div>
        </div>
        <!--card MISIÓN end-->
      </div>
      <div class="col-lg-6 py-2">
        <!--card VISIÓN-->
        <div class="card">
          <div class="card-header card-header-nosotros">
            <h5 class="card-title">Visión</h5>
          </div>
          <div class="card-body card-body-nosotros">
            <p class="card-text">
              Ser en el mercado de la evaluación un referente distintivo por
              nuestras capacidades innovadoras que nos definirá como una marca
              exitosa en la evaluación, tendremos la mejor oferta en cuanto al
              desarrollo de evaluaciones; seremos un equipo de especialistas con
              capacidad para trabajar con altos estándares de calidad, armonía,
              espíritu de compañerismo y trabajo en equipo, lo que nos
              convertirá en el equipo evaluador de mayor presencia nacional.
            </p>
          </div>
        </div>
        <!--card VISIÓN end-->
      </div>
    </div>

    <h2 class="my-h2 text-center">EQUIPO EVALUADOR</h2>
    <div class="row justify-content-center" id="equipo">
      <div class="col-sm-6 col-lg-3 py-3">
        <div class="card">
          <div class="card-body">
            <img src="@/assets/img/equipo/vmi.png" class="w-100" alt="..." />
          </div>
          <div class="card-header card-header-nosotros ext-box">
            <div class="int-box">
              <b>Vicente Martínez Iniesta</b>
              <br />
              Evaluador
              <br />
              <ul class="a">
                <li>Metodología del marco lógico</li>
                <li>Presupuesto basado en resultados</li>
                <li>Evaluación de programas y fondos federales</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 py-3">
        <div class="card">
          <div class="card-body">
            <img src="@/assets/img/equipo/jejh.png" class="w-100" alt="..." />
          </div>
          <div class="card-header card-header-nosotros ext-box">
            <div class="int-box">
              <b>José Eduardo Jerónimo Herrera</b>
              <br />
              Evaluador
              <br />
              <ul class="a">
                <li>Metodología del marco lógico</li>
                <li>Presupuesto basado en resultados</li>
                <li>Evaluación de programas y fondos federales</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 py-3">
        <div class="card">
          <div class="card-body">
            <img src="@/assets/img/equipo/mrm.png" class="w-100" alt="..." />
          </div>
          <div class="card-header card-header-nosotros ext-box">
            <div class="int-box">
              <b>Miriam Ruíz Martínez</b>
              <br />
              Evaluadora
              <br />
              <ul class="a">
                <li>Metodología del marco lógico</li>
                <li>Presupuesto basado en resultados</li>
                <li>Evaluación de programas y fondos federales</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 py-3">
        <div class="card">
          <div class="card-body">
            <img src="@/assets/img/equipo/agp.png" class="w-100" alt="..." />
          </div>
          <div class="card-header card-header-nosotros ext-box">
            <div class="int-box">
              <b>Andrea González Palma</b>
              <br />
              Evaluadora
              <br />
              <ul class="a">
                <li>Metodología del marco lógico</li>
                <li>Presupuesto basado en resultados</li>
                <li>Evaluación de programas y fondos federales</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 py-3">
        <div class="card">
          <div class="card-body">
            <img src="@/assets/img/equipo/ramon2.png" class="w-100" alt="..." />
          </div>
          <div class="card-header card-header-nosotros ext-box">
            <div class="int-box">
              <b>Ramón Cuevas Martínez</b>
              <br />
              Evaluador
              <br />
              <ul class="a">
                <li>Metodología del marco lógico</li>
                <li>Presupuesto basado en resultados</li>
                <li>Evaluación de programas y fondos federales</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NosotrosPage",
};
</script>

<style></style>