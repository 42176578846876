<template>
  <div class="container-fluid">
    <h1 class="my-h1">Programa de Certificación</h1>
    <div class="center">
      <img
        src="@/assets/img/procesodecertificacion.jpeg"
        alt=""
        class="center img-fluid"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramaDeCertificacion",
};
</script>

<style>
</style>