<template>
  <div class="container-fluid">
    <h1 class="my-h1">Consultoría</h1>
    <div class="general-text">
      Los trabajos que realizamos se fundamentan en el artículo 79 de la Ley
      General de Contabilidad Gubernamental, donde se indica la obligación de
      establecer un Programa Anual de Evaluación que permita evaluar los
      programas presupuestarios seleccionados por los entes gubernamentales, a
      partir de su diseño, que se basa invariablemente en la metodología del
      Marco Lógico. <br /><br />
      Evaluamos programas presupuestarios gubernamentales determinados en el
      Programa Anual de Evaluación (PAE) de los entes gubernamentales, con la
      finalidad de proveer información que retroalimente su diseño, gestión y
      resultados. El esbozo que implementamos para realizar las evaluaciones a
      programas presupuestales es el siguiente:
      <ul>
        <li>
          Diseño y desarrollo el Programa Anual de Evaluación, para el
          cumplimiento de los objetivos plasmados en su Plan de Desarrollo y sus
          Programas Estratégicos.
        </li>
        <li>
          Elaboración de los Lineamientos Generales para la Evaluación de los
          Programas Presupuestarios.
        </li>
        <li>Construcción de los Términos de Referencia para la Evaluación.</li>
        <li>
          Evaluación de los programas presupuestarios y/o de los fondos
          federales.
        </li>
      </ul>
    </div>
    <h2 class="my-h2">Con las evaluaciones que realizamos se conoce:</h2>
    <div class="general-text">
      <ul>
        <li>El eficiente diseño de los programas presupuestarios.</li>
        <li>
          Las fortalezas, oportunidades, debilidades y amenazas (FODA), de los
          programas presupuestarios valorados.
        </li>
        <li>La efectividad de los programas presupuestarios.</li>
        <li>
          El conocimiento que tienen los funcionarios de los resultados
          esperados luego de la operación de los programas del año evaluado.
        </li>
        <li>Aspectos susceptibles de Mejora.</li>
        <li>Hallazgos.</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConsultoriaView",
};
</script>

<style>
</style>