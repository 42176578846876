<template>
  <div class="container-fluid">
    <!--Table -->
    <div class="col-12 table-title">
      Nombre del taller: Implantación del Sistema de Gestión para Resultados en
      el Sector Público
    </div>
    <div class="col-12 table-white">
      <b>Objetivo:</b>
      <ul>
        <li>
          Comprender el marco conceptual en el que se sustenta la AGpR y conocer
          la situación actual y los desafíos que enfrentan los gobiernos en la
          implementación de los cambios institucionales para mejorar la
          efectividad de estos, mediante la implantación del Sistema de gestión
          para resultados en el sector público.
        </li>
      </ul>
    </div>
    <div class="col-12 table-blue">
      <b>Descripción:</b><br />
      <ul>
        <li>Reconocer las características de los gobiernos.</li>
        <li>
          Identificar el aporte de la AGpR a los procesos gubernamentales.
        </li>
        <li>Conocer elementos que definen el origen de la AGpR.</li>
        <li>
          Identificar la situación actual de los gobiernos respecto al
          desarrollo e implementación de la AGpR.
        </li>
        <li>
          Identificar las fortalezas y debilidades de los sistemas de
          planificación, presupuesto, gestión financiera, gestión de programas y
          proyectos y seguimiento - evaluación.
        </li>
      </ul>
    </div>
    <div class="col-12 table-white">
      <b>Características:</b>
      <br />
      Dirigido a: Alta autoridad, funcionarios/as y personas designado para la
      implantación del Sistema de Gestión para Resultados en el Sector
      Público.<br /><br />
      Garantía de satisfacción: Avalamos la transmisión de conocimientos a
      través der una metodología que permita elegir la información para el
      diseño de productos o resultados.<br /><br />
      Constancia: Al término del curso se otorgará una constancia de
      participación con valor curricular, con la insignia de Visión y Estrategia.
    </div>
    <div class="col-12 table-blue">
      <b>Temario:</b>
      <br />
      <b>Herramienta de partida:</b> Comprensión de un Sistema de Gestión para
      Resultados.
      <ul class="ul-clean">
        <li>1.1. Participación y tipos de participación</li>
        <li>1.2. Consulta ciudadana</li>
        <li>1.3. Sistemas de innovación en participación ciudadana</li>
        <li>1.4. Partes interesadas</li>
        <li>1.5. Definición de un Sistema de Gestión para Resultados</li>
      </ul>
      <b>
        Módulo 1: Planeación de un Sistema de Administración por Resultados
      </b>
      <ul class="ul-clean">
        <li>1.1 Planeación estratégica</li>
        <li>1.2 Planeación operativa</li>
        <li>1.3 Planeación participativa</li>
        <li>1.4 Metodología del Marco Lógico (MML)</li>
        <li>1.5 Gestión de recursos</li>
        <li>1.6 Resultados comprometidos</li>
      </ul>
      <b> Módulo 3: Presupuesto basado en Resultados (PbR) </b>
      <ul class="ul-clean">
        <li>3.1Recursos para la planeación estratégica</li>
        <li>3.2 Planeación Anual</li>
        <li>3.3 Sistema de Evaluación del Desempeño</li>
        <li>3.4 Formatos de PbR</li>
        <li>3.5 Herramientas para la consolidación del PbR</li>
      </ul>
      <b> Módulo 4: Diseño de indicadores estratégicos y de gestión </b>
      <ul class="ul-clean">
        <li>4.1 Conceptualización y diseño de indicadores</li>
        <li>
          4.2 Tipos de indicadores (Gestión, procesos, riesgos, estratégicos y
          de desempeño)
        </li>
        <li>4.3 Control de riesgos</li>
        <li>
          4.4 Cuadro de control Sistema de Administración Gubernamental por
          Resultados (SAGUR)
        </li>
      </ul>
      <b> Módulo 5: Monitoreo y control </b>
      <ul class="ul-clean">
        <li>5.1 Consideraciones iniciales del control</li>
        <li>5.2 Aseguramiento en la calidad de un proyecto</li>
        <li>5.3 Gestión de conflictos</li>
        <li>5.4 Herramientas de monitoreo</li>
      </ul>
      <b> Módulo 6: Gestión de la evidencia </b>
      <ul class="ul-clean">
        <li>6.1 Conceptualización de evaluación</li>
        <li>6.2 Herramientas de evaluación</li>
        <li>6.3 Metodologías de evaluación</li>
        <li>6.4 Evaluaciones externas e internas</li>
        <li>6.5 Evaluación de desempeño</li>
        <li>6.6 Gestión de la evidencia</li>
        <li>6.7 Evaluación para la toma de decisiones</li>
      </ul>
      <b> Módulo 7: Comunicación de resultados </b>
      <ul class="ul-clean">
        <li>7.1 Estrategias de comunicación</li>
        <li>7.2 Plan de comunicación interna y externa</li>
        <li>7.3 Identificación de audiencias</li>
        <li>7.4 Medios de comunicación</li>
        <li>7.5 Retroalimentación con usuarios</li>
      </ul>
    </div>
    <div class="col-12 table-white">
      <b>Horas de duración:</b>11 días (36 horas)
    </div>
    <div class="col-12 table-blue">
      <b>Fecha de realización: </b>Del 2 al 5, del 8 al 12 y del 15 al 16 de
      mayo de 2023
    </div>
    <!--Table end-->
  </div>
</template>
      
  <script>
export default {
  name: "CursoView",
};
</script>
      
  <style>
</style>