<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Medición de la calidad del gasto municipal en Edomex
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Medición de la calidad del gasto municipal en Edomex</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/measure.jpeg"
          class="w-100 my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          <b> Un centavo ahorrado es un centavo ganado: Benjamin Franklin </b>
        </p>
        <p>
          Hay muy pocas herramientas para medir si los recursos públicos se
          están ejerciendo de tal manera que se cumplan los propósitos para los
          que fueron destinados, una de ellas, establecida incluso en la
          Constitución Federal (Artículo 134) es el Programa Anual de Evaluación
          (PAE), el cual permite garantizar un uso eficaz y eficiente de los
          recursos con el objeto de elevar el impacto del gasto, es decir,
          identifica si el presupuesto ejercido está generando el beneficio
          esperado de acuerdo a las necesidades y expectativas de una población.
          En resumen, los entes públicos tienen la obligación constitucional de
          conocer si los programas presupuestarios funcionan, de informar a la
          población de los resultados de dichos programas, y de medir la calidad
          del gasto público para traducirla en mejor calidad de vida para la
          ciudadanía.
        </p>
        <p>
          <b>El PAE permite</b> realizar evaluaciones anuales de desempeño a
          fondos y programas presupuestarios federales, estatales y municipales,
          según corresponda, instaurando tiempos y responsabilidades para el
          cumplimiento de los objetivos de la valoración, logrando con ello
          consolidar la Gestión para Resultados (GpR), a través del uso e
          implementación de los instrumentos del Presupuesto basado en
          Resultados (PbR).
        </p>
        <p>
          La única manera de garantizar un gasto público de calidad es evaluando
          el impacto real que tienen los programas presupuestarios en la
          sociedad, a través de tres acciones: transparentando los procesos y
          resultados del sector público, orientando la programación y ejecución
          del gasto público a un modelo por resultados presupuestarios, y
          considerando los resultados de evaluaciones anteriores para el diseño
          crítico de programas presupuestarios.
        </p>
        <p>
          El IIEPM, desarrollado por Visión y Estreategia y reconocido como una
          práctica exitosa por CONEVAL, plantea que los programas en la
          administración pública municipal, las políticas públicas y los fondos
          de aportación federal,
          <b
            >representan la herramienta que determina la calidad del gasto
            público.</b
          >
        </p>
        <p>
          La radiografía del gasto municipal del edomex nos indica que la suma
          del presupuesto de egresos 2020 de los 125 municipios mexiquenses
          ascendió a 74 mil 788 millones 242 mil 441 pesos con 50 centavos,
          cifra que representa 4 mil millones más que la suma del presupuesto de
          egresos del mismo año, de los estados de Nayarit, Zacatecas y Colima.
        </p>
        <p>
          Para esta tercera edición del Índice,
          <b>el promedio general de desempeño alcanzado fue de 46 de 100</b>
          puntos posibles; apenas un punto arriba del año anterior, lo que
          representa un estancamiento en los procesos de evaluación y la mejora
          del gasto público de calidad, sobre todo en los 24 municipios sin
          registro de gestión en su programa anual de evaluación, lo que
          equivale a total opacidad.
        </p>
        <p>
          Visión y Estreategia entregó reconocimiento a los 17 mejores
          municipios. Con puntuación perfecta y calificación de 10: los
          municipios de Tultitlán, Tlalmanalco, Malinalco, Zinacantepec y
          Nicolás Romero. Con calificación de 9.5: Chalco, San Felipe del
          Progreso, Toluca, Atizapán Santa Cruz, Tianguistenco, Atlacomulco y
          Tonatico. Y con calificación de 9.0: Ixtlahuaca, Villa Guerrero,
          Huixquilucan, Ixtapan de la Sal y Almoloya del Rio. La caída más
          drástica la registró el ayuntamiento de Villa Victoria, que en 2020
          logró una calificación de 91.5, uno de los mejores calificados, y
          ahora en 2021 obtuvo calificación de cero.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>