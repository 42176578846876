<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Administración Pública para Resultados
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Administración Pública para Resultados</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/results.jpeg"
          class="w-100  my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          <b>
            <em>
              Todos los organismos públicos cumplen cabalmente con sus programas
              de trabajo, entonces… ¿Porqué no se observan los resultados de ese
              trabajo?
            </em>
          </b>
        </p>
        <p>
          Datos conservadores indican que el 10% de los planes de desarrollo
          municipales se ejecutan de manera adecuada, sin embargo, la
          información que se sistematiza en ese porcentaje no está sujeta a
          ninguna comprobación, pues solo se muestran los informes de
          cumplimiento, no las evidencias de cómo se cumplieron. Los motivos son
          múltiples, aunque el más recurrente es que no se transparenta la
          información debido a que no está lo suficientemente soportada por
          evidencias. Entonces, sí 90% de los planes no se cumplen y 10% de los
          que se cumplen no registran evidencia de cumplimiento: “tenemos un
          problema Houston”.
        </p>
        <p>
          La administración pública no alcanza los resultados deseados por
          directivos ni por la ciudadanía por el simple hecho de que los
          programas de trabajo no están diseñados para ello, solo son diseñados
          para cumplir atribuciones. Supongamos que laboramos en la Secretaría
          de Desarrollo Social y es nuestra atribución legal entregar apoyos
          alimentarios, seguramente programaremos y entregaremos todos los
          apoyos que están considerados en el presupuesto anual, aunque
          desafortunadamente solo hayan alcanzado para el 10% de las personas
          que tienen necesidad de dicho apoyo.
        </p>
        <p>
          Este hecho desafortunado ya no es solo un tema de papeles de trabajo y
          gráficas de cumplimiento, trasciende negativamente en el momento que
          lo comunicamos, lo informamos y lo colocamos como un gran logro,
          porque la ciudadanía siente que es una tomada de pelo, dado que ve
          como prevalecen muchas necesidades a su alrededor. Las y los
          servidores públicos no deben enfocarse en prometer más y más acciones,
          es importante que ofrezcan buenos resultados.
        </p>
        <p>
          Frente a los nuevos retos de pluralidad, alternancia y diversidad
          democrática, es necesario fortalecer la acción gubernamental con
          acciones, programas y políticas que mejoren el desempeño
          institucional.
        </p>
        <p>
          La Carta Iberoamericana de Calidad en la Gestión Pública (2008) señala
          dos propósitos fundamentales de un buen gobierno democrático: 1)Toda
          gestión pública debe estar referenciada a la satisfacción del
          ciudadano, ya sea como usuario o beneficiario de servicios y programas
          públicos, o como legítimo participante en la formulación, ejecución y
          control de las políticas públicas; y 2) La gestión pública tiene que
          orientarse a resultados, sujetándose a diversos controles sobre sus
          acciones, suponiendo la responsabilización del ejercicio de la
          autoridad pública por medio del control social y la rendición
          periódica de cuentas.
        </p>
        <p>
          Los gobiernos deben enfocarse en la adopción de un Sistema de
          Administración para Resultados que les facilite una planeación
          efectiva basada en la eficacia; es decir, basar sus decisiones
          procurando la creación de valor público; entendiéndolo como el
          compromiso por establecer una gestión de calidad en trámites y
          servicios, centrando su atención en que sean simples, de fácil acceso
          y útiles para el ciudadano.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>