<template>
  <div class="container-fluid">
    <!--Table 2-->
    <div class="col-12 table-title">
      Nombre del Taller: Elaboración de Términos de Referencia
    </div>
    <div class="col-12 table-white">
      <b>Objetivo:</b>
      <ul>
        <li>
          Conocer las características que conforman los Términos de Referencia
          para el diseño de un programa anual de evaluación a efecto de quien
          lleve a cabo las evaluaciones tenga claro las condiciones de cómo se
          debe desarrollarlo.
        </li>
      </ul>
    </div>
    <div class="col-12 table-blue">
      <b>Descripción:</b><br />
      Con el taller los asistentes aprenderán a elaborar la herramienta
      necesaria para establecer el proceso mediante el cual se establecen las
      directrices que se deben realizar al elaborar las evaluaciones
      establecidas en el programa (s) anual (es) de Evaluación (es).
    </div>
    <div class="col-12 table-white">
      <b>Características:</b><br />
      Dirigido a: Profesionistas, independientes, consultores, académicos,
      estudiantes, titulares de las Unidades de Información, Planeación,
      Programación y Evaluación (UIPPE), similares de los entes gubernamentales
      federales, estatales o municipales, o personal designado interesado en
      conocer sobre la creación de los términos de referencia para llevar a cabo
      las evaluaciones establecidas en Programas Anuales de Evaluación.
      <br /><br />
      Garantía de satisfacción: Avalamos la transmisión de conocimientos a
      través der una metodología que permita elegir la información para el
      diseño de productos o resultados.
      <br /><br />
      Constancia: Al término del curso se otorgará una constancia de
      participación con valor curricular, con la insignia de Visión y Estrategia.
    </div>
    <div class="col-12 table-blue">
      <b>Temario:</b>
      <ol>
        <li>Contenido de los términos de referencia.</li>
        <li>Tipos de evaluaciones.</li>
        <li>
          Herramientas de trabajo para la obtención de la información para el
          desarrollo de las evaluaciones.
        </li>
      </ol>
    </div>
    <div class="col-12 table-white">
      <b>Horas de duración:</b>4 días (12 horas)
    </div>
    <div class="col-12 table-blue">
      <b>Fecha de realización: </b>Del 18 al 21 de abril de 2023
    </div>
    <!--Table 2 end-->
  </div>
</template>
          
  <script>
export default {
  name: "CursoView",
};
</script>
          
  <style>
</style>
  
  