<template>
  <div class="container-fluid">
    <!--Table 1-->
    <div class="col-12 table-white">
      <div class="col-12 table-title">
        Nombre del curso: Inducción al Programa Anual de Evaluación
      </div>
      <b>Objetivo:</b>
      <ul>
        <li>
          Instruir, orientar y dar a conocer la estructura que debe contener un
          programa anual de evaluación, así como que programas presupuestarios y
          fondos federales y/o estatales deben elegirse para ser considerados en
          este de acuerdo con la normatividad vigente.
        </li>
      </ul>
    </div>
    <div class="col-12 table-blue">
      <b>Descripción:</b><br />
      El curso permitirá a los participantes contar con las herramientas
      necesarias para estar en condiciones de elaborar un programa anual de
      evaluación independiente del ámbito de gobierno para el que se requiera.
    </div>
    <div class="col-12 table-white">
      <b>Características:</b><br />
      Dirigido a: Profesionistas, independientes, consultores, académicos,
      estudiantes, titulares de las Unidades de Información, Planeación,
      Programación y Evaluación (UIPPE), similares de los entes gubernamentales
      federales, estatales o municipales, o personal designado interesado en
      conocer sobre el diseño de Programas Anuales de Evaluación. <br /><br />
      Garantía de satisfacción: Avalamos la transmisión de conocimientos a
      través der una metodología que permita elegir la información para el
      diseño de productos o resultados.
      <br /><br />
      Constancia: Al término del curso se otorgará una constancia de
      participación con valor curricular, con la insignia de Visión y Estrategia.
    </div>
    <div class="col-12 table-blue">
      <b>Temario:</b>
      <ul class="ul-clean">
        <li>1. Marco teórico y conceptual.</li>
        <li>
          2. Consideraciones generales.
          <ul class="ul-clean">
            <li>2.1. Objetivo general.</li>
            <li>2.2. Objetivos específicos.</li>
          </ul>
        </li>
        <li>3. Glosario.</li>
        <li>4. Diagnóstico de programas.</li>
        <li>5. Monitoreo.</li>
        <li>
          6. Proceso de evaluación.
          <ul class="ul-clean">
            <li>6.1. Coordinación.</li>
            <li>6.2. Contratación.</li>
            <li>6.3. Presupuesto.</li>
            <li>6.4. Difusión.</li>
          </ul>
        </li>
        <li>7. Valoración de programas presupuestarios.</li>
        <li>
          8. Uso de información del desempeño.
          <ul class="ul-clean">
            <li></li>
            8.1. Información estratégica para la toma de decisiones.
            <li></li>
            8.2. Aspectos Susceptibles de Mejora.
          </ul>
        </li>
        <li>9. Consideraciones finales.</li>
        <li>10. Cronograma de ejecución.</li>
        <li>11. Transitorios.</li>
        <li>12. Anexos.</li>
      </ul>
    </div>
    <div class="col-12 table-white">
      <b>Horas de duración:</b>7 días (14 horas).
    </div>
    <div class="col-12 table-blue">
      <b>Fecha de realización: </b>Del 27 al 31 de marzo, 3 y 4 de abril de 2023
    </div>
    <!--Table 1 end-->
  </div>
</template>
        
<script>
export default {
  name: "CursoView",
};
</script>
        
<style>
</style>