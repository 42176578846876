<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Problemas públicos mal diagnosticados
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Problemas públicos mal diagnosticados</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/mistake.jpeg"
          class="w-100 my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          <b> “Algunos remedios son peores que la enfermedad” </b>
        </p>
        <p>
          Mientras las encuestadoras, los medios de comunicación y las áreas de
          comunicación social repiten hasta el cansancio que la inseguridad
          pública es el principal problema del país, muchos vemos que se trata
          de un tema bastante mal diagnosticado, porque se etiqueta al igual
          como inseguridad a las extorsiones en los estados de México y
          Michoacán, a la violencia de cárteles en el norte y a los feminicidios
          en todo el país.
        </p>
        <p>
          Si bien sabemos cuáles son los problemas, no tenemos un diagnóstico
          claro de donde están, lo que limita a los funcionarios y autoridades
          de los tres poderes y de los tres ámbitos de gobierno, en la
          formulación de estrategias que permitan avanzar en la solución. La
          principal causa de los malos diagnósticos es que NO TENEMOS UN
          MONITOREO SISTEMÁTICO DE LOS PROBLEMAS PÚBLICOS.
        </p>
        <p>
          <b> ALGUNOS PROBLEMAS PÚBLICOS </b>
        </p>
        <p>
          <b>Desempleo:</b> Existen 2 millones de mexicanos sin empleo,
          principalmente personas entre los 25 y 44 años de edad y se han
          perdido 600 mil empleos en lo que va del año.
        </p>
        <p>
          <b>Violencia de género:</b> Existen 25 Declaratorias de Alertas de
          Violencia de Género contra las Mujeres (AVGM) donde se incluyen 643
          municipios de todo el país, que arrojan diariamente el fallecimiento
          10 mujeres por agresiones intencionales.
        </p>
        <p>
          <b>Crimen Organizado:</b> 6 de las ciudades más violentas del mundo se
          encuentran en México, consecuentemente se registran más de 36 mil
          homicidios al año.
        </p>
        <p>
          <b>Corrupción.</b> Ocupamos el segundo lugar en la práctica del
          soborno en América Latina, solo detrás de Venezuela.
        </p>
        <p>
          <b>Insalubridad.</b> Un 10% de la población mexicana que no tiene
          acceso a agua potable.
        </p>
        <p>
          <b>Desnutrición Infantil.</b> 880 mil niños sufren desnutrición
          crónica en el país, y paradójicamente, también tenemos un grave
          problema de <b>obesidad que alcanza</b> el 18% de los niños entre 5 y
          11 años; esta enfermedad aumenta hasta el 25% en jóvenes de entre 12 y
          19 años y llega hasta el 40% en mayores de 20 años.
        </p>
        <p>
          <b>Pobreza.</b> Como origen y destino de todo este conjunto de males,
          se estima que el 44% de la población vive en condiciones de pobreza,
          con cerca de 56 millones de personas.
        </p>
        <p>
          Entender cuáles son los problemas a los que se enfrenta México es un
          primer paso para solucionarlos, lo que nos lleva a delimitar sus
          alcances, repartir responsabilidades entre los niveles de gobierno y a
          que las políticas públicas sean implementadas de forma transversal en
          todas las instituciones.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>