<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Gobiernos con resultados que no son apreciados por los electores
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">
      Gobiernos con resultados que no son apreciados por los electores
    </h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/decline.jpeg"
          class="w-100 my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          <b>
            “La falta de un bien (hospital o patrulla) no necesariamente es un
            problema”.
          </b>
        </p>
        <p>
          Estamos a escasas tres semanas de ver una de las escenas más
          frustrantes para los alcaldes y diputados, que en algunos casos
          terminará con su carrera política: A pesar de que muchos trabajaron a
          tope, repartieron despensas, construyeron clínicas, dieron miles de
          apoyos… perderán las elecciones.
        </p>
        <p>
          ¿Cuál es el motivo de este voto de castigo? La ciudadanía aprecia que
          NO LE RESOLVIERON NINGÚN PROBLEMA.
        </p>
        <p>
          Cuando un alcalde en su plan de gobierno destina una gran cantidad de
          presupuesto a la compra de patrullas o a construir un hospital que con
          los años se deteriora o inclusive no cuenta con insumos y personal
          médico, siempre queda la duda si lo hizo pensando en resolver los
          problemas de inseguridad y salud, o solo realiza estas acciones para
          mejorar su imagen personal como gobernante, pues todos nos hemos
          preguntado por qué los recursos destinados a seguridad y salud son
          cada vez más altos pero estos problemas siguen en aumento.
        </p>
        <p>
          Esta última pregunta es un tanto sencilla de responder, pero compleja
          de poner en práctica; aumentar el presupuesto de un programa o
          política pública jamás logrará resolver un problema si no logra
          diseñarse con un enfoque basado en resultados. Los resultados no se
          miden con el número de hospitales construidos, su impacto real se ve
          reflejado en los indicadores de salud pública.
        </p>
        <p>
          La complejidad de poner en práctica un modelo de Gestión para
          Resultados (GpR), está en su transversalidad organizacional,
          directiva, operacional y funcional, específicamente pone énfasis en la
          creación de valor público, es decir, qué se hace, qué se logra y cuál
          es el impacto real en la creación de oportunidades para bienestar de
          la población. Este es un modelo cíclico que contempla las etapas de:
          1) Planificación orientada a resultados; 2) Programación de proyectos;
          3) presupuesto por resultados; 4) Gestión financiera pública; 5)
          Gestión de programas y proyectos; y 6) Evaluación y control.
        </p>
        <p>
          Pese a que esto no es un tema nuevo, pues sus inicios en México se
          remontan a finales de la década de 1990, no se hacen palpables los
          beneficios de emplear el modelo de Gestión por Resultados. Es evidente
          la necesidad de implementarlo en toda la administración pública
          mexicana. Las posibles ausencias o fallas de su implementación pueden
          ser, por un lado, la falta de capacitación u orientación en el tema y,
          por otra parte, la falta de personal experimentado y calificado que
          pueda gestionar este modelo.
        </p>
        <p>
          Durante mis años como administrador público vi como en una comunidad
          donde las infecciones estomacales eran un problema de salud pública se
          construyó una clínica, a pesar de ello, cada vez había más enfermos no
          obstante que había médicos todo el tiempo. El problema se resolvió
          muchos años después cuando se tapó el canal de drenaje a cielo abierto
          que las provocaba. LAS PERSONAS QUERÍAN SALUD, NO UNA CLÍNICA, PERO
          NADIE LO DIAGNOSTICÓ CORRECTAMENTE.
        </p>
        <p>
          La GpR se enfoca en emprender acciones que resuelvan los problemas y
          no solo hacen como que se atienden.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>