<template>
  <div class="container-fluid">
    <h1 class="my-h1">En que consiste</h1>
    <div class="general-text">
      Es el instrumento normativo donde se establecen las evaluaciones que se
      llevarán a cabo a los programas, subprogramas, estrategias y/o políticas
      durante cada uno de los ejercicios fiscales anuales. Este instrumento
      permite cumplir con la obligación establecida para los entes públicos.
      <br />
      <br />
      Representa una necesidad de planear y ejecutar el proceso de evaluación,
      asegurando que los programas a evaluar se seleccionen estratégicamente,
      tiene como propósitos generales lo siguiente:
      <ul>
        <li>
          Determinar los tipos de evaluación que se aplicarán a los programas,
          subprograma, estrategias y/o políticas, incluyendo los fondos de
          aportaciones federales durante el ejercicio fiscal que corresponda.
        </li>
        <li>
          Establecer el cronograma de ejecución de las evaluaciones en
          cumplimiento al programa, subprograma, estrategias y/o políticas para
          el ejercicio fiscal que corresponda.
        </li>
        <li>
          Vincular el cronograma de ejecución de las evaluaciones con el
          calendario de las actividades de la programación y presupuestación en
          términos de lo dispuesto en el artículo 111, último párrafo, de la Ley
          Federal de Presupuesto y Responsabilidad Hacendaria y 22 de su
          Reglamento.
        </li>
        <li>
          Articular los resultados de las evaluaciones realizadas en
          cumplimiento al programa, como elemento relevante del presupuesto
          basado en resultados y del Sistema de Evaluación del Desempeño.
        </li>
      </ul>
    </div>
    <h2 class="my-h2">
      Guía de monitoreo y evaluación para estrategias y programas sociales
    </h2>
    <div class="general-text">
      Es el documento elaborado como recurso de apoyo al seguimiento y
      evaluación de las iniciativas de desarrollo social compila los conceptos y
      metodologías esenciales para el desarrollo de una investigación de
      carácter evaluativo y ha sido diseñada para los responsables de
      organizaciones sociales que tienen el desafío de diseñar y desarrollar
      proyectos que tengan un impacto relevante sobre la calidad de vida de
      miles de hombres y mujeres que viven en una condición de marginación del
      bienestar económico y social.
    </div>
    <div class="mb-3" style="text-align: center">
      <a
        class="download-text"
        href="/pdf/Guia_M_y_E_Programas_Sociales.pdf"
        target="_blank"
      >
        Puedes descargar la guía práctica aquí
        <i class="fa fa-cloud-download download-text"></i>
      </a>
    </div>
    <h2 class="my-h2">
      Guía para la evaluación de los fondos federales que integran el Ramo
      General 33
    </h2>
    <div class="general-text">
      Orienta a los servidores públicos de la federación y de los gobiernos
      locales en la evaluación de los fondos, mediante ocho capítulos. El
      primero presenta la importancia del Ramo General 33 y de su evaluación.
    </div>
    <div class="mb-3" style="text-align: center">
      <a
        class="download-text"
        href="/pdf/Guia_Eval_FAF_RG33.pdf"
        target="_blank"
      >
        Puedes descargar la guía práctica aquí
        <i class="fa fa-cloud-download download-text"></i>
      </a>
    </div>
    <h2 class="my-h2">Marco legal</h2>
    <div class="general-text">
      Artículos 26 y 134 de la Constitución Política de los Estados Unidos
      Mexicanos; 9, 31 y 37 de la Ley Orgánica de la Administración Pública
      Federal; 9 de la Ley de Planeación; 45, 78, 85 110 y 111 de la Ley Federal
      de Presupuesto y Responsabilidad Hacendaria; 72 al 85 de la Ley General de
      Desarrollo Social; 26 del Decreto de Presupuesto de Egresos de la
      Federación para el Ejercicio Fiscal que corresponda; 4 del Reglamento
      Interior de la Secretaría de Hacienda y Crédito Público; 6 del Reglamento
      Interior de la Secretaría de la Función Pública; 11 del Decreto por el que
      se regula el Consejo Nacional de Evaluación de la Política de Desarrollo
      Social (CONEVAL), y Lineamientos Generales para la Evaluación de los
      Programas Federales de la Administración Pública Federal.
    </div>
    <h2 class="my-h2">
      Relación del Programa Anual de Evaluación con otras disposiciones legales
    </h2>
    <div class="general-text">
      Cabe mencionar la existencia de algunos sistemas de evaluación previos a
      la implantación del Sistema de Evaluación al Desempeño en donde surge el
      Programa Anual de Evaluación que de alguna forma contribuyeron a su
      surgimiento:
      <ul>
        <li>La Ley General del Sistema Nacional Anticorrupción.</li>
        <li>Ley General de Responsabilidades Administrativas.</li>
        <li>Ley Orgánica del Tribunal Federal de Justicia Administrativa.</li>
        <li>Ley Orgánica de la Administración Pública Federal.</li>
        <li>Ley de Fiscalización y Rendición de Cuentas de la Federación.</li>
        <li>Código Penal.</li>
        <li>
          Ley de la Fiscalía General de la República (creación de la Fiscalía
          Anticorrupción).
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnQueConsisteView",
};
</script>

<style>
</style>