<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Encuesta Nacional de Calidad de Servicios Públicos
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Encuesta Nacional de Calidad de Servicios Públicos</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/survey.jpeg"
          class="w-100 my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          <b> “Predecir el diluvio no cuenta, construir el arca, si” </b>
        </p>
        <p>
          Identifico dos brechas relevantes en cuanto al destino de los recursos
          públicos, la primera brecha tiene que ver con las prioridades del
          gobernante a la hora de programar el gasto, cuya orientación deriva
          generalmente en un mayor número de programas sociales, como apoyo a
          las siguientes elecciones, y también en la construcción de obras
          emblemáticas, que le permitirán “pasar a la posteridad”. Digamos que,
          en este caso, la brecha consiste en que dichas acciones no
          necesariamente coinciden con las necesidades de los habitantes.
        </p>
        <p>
          La segunda brecha es la relativa a la necesidad de mejorar la calidad
          de los servicios y trámites gubernamentales, un pendiente permanente
          por atender por parte de la autoridad. Para ello el INEGI ha diseñado
          un instrumento que se denomina “Encuesta Nacional de Calidad e Impacto
          Gubernamental” (ENCIG), que señala los faltantes y áreas de
          oportunidad en los servicios públicos que permitiría a los habitantes
          alcanzar un mejor nivel de vida.
        </p>
        <p>
          Este ejercicio se realiza cada dos años con la finalidad de atender
          temas relevantes para la sociedad mexicana, con información sobre la
          gestión y el desempeño gubernamental a partir de la opinión y
          experiencias de la ciudadanía en las 32 entidades del país.
        </p>
        <p>
          En la edición 2021, de reciente publicación, mostró que los
          <b
            >servicios públicos de recolección de basura y el acceso a agua
            potable son en los que mayor satisfacción tienen los mexicanos</b
          >, aún con el decrecimiento en satisfacción del 3% sobre el acceso a
          agua potable con respecto al 2019; en sentido contrario también
          evidenció que
          <b
            >el mantenimiento de calles y avenidas, y la seguridad pública que
            prestan las policías en los tres niveles de gobierno son los
            servicios peor evaluados por la población.</b
          >
        </p>
        <p>
          En otro contexto, como profesor de una universidad pública, me alegró
          saber que la educación pública universitaria (las universidades
          públicas) se sigue situando como el servicio con mayor aprobación,
          mientras que el servicio de transporte público automotor es el de
          mayor desaprobación por los usuarios.
        </p>
        <p>
          <b>
            El porcentaje promedio de aprobación de servicios públicos 2021 se
            encuentra en un 43% a nivel nacional, siendo solo 5 entidades las
            que se sitúan por debajo de este promedio; Quintana Roo, México,
            Oaxaca, Tabasco y Guerrero, y 6 entidades con un promedio con mas de
            56.6% de satisfacción general: Nuevo León, Coahuila, Guanajuato,
            Nayarit, Yucatán y Querétaro.
          </b>
        </p>
        <p>
          <b>
            Para el caso del Estado de México el tema de la corrupción es de
            gran preocupación, pues cerca del 90% de la población considera que
            es muy frecuente en la policía, lo que contribuye a que el 80% de
            los mexiquenses consideren la inseguridad y delincuencia como el
            problema más importante de la entidad.
          </b>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>