<template>
  <div class="container-fluid">
    <!--Table Módulo I -->
    <div class="col-12 table-title">
      Nombre del curso: Módulo I. Planeación de un Sistema de Administración por
      Resultados
    </div>
    <div class="col-12 table-white">
      <b>Objetivo:</b>
      <ul>
        <li>
          Comprender el marco conceptual en el que se sustenta la AGpR y conocer
          la situación actual y los desafíos que enfrentan los gobiernos en la
          implementación de los cambios institucionales para mejorar la
          efectividad de estos, mediante la implantación del Sistema de gestión
          para resultados en el sector público.
        </li>
      </ul>
    </div>
    <div class="col-12 table-blue">
      <b>Descripción:</b><br />
      <ul>
        <li>Reconocer las características de los gobiernos.</li>
        <li>
          Identificar el aporte de la AGpR a los procesos gubernamentales.
        </li>
        <li>Conocer elementos que definen el origen de la AGpR.</li>
        <li>
          Identificar la situación actual de los gobiernos respecto al
          desarrollo e implementación de la AGpR.
        </li>
        <li>
          Identificar las fortalezas y debilidades de los sistemas de
          planificación, presupuesto, gestión financiera, gestión de programas y
          proyectos y seguimiento - evaluación de los gobiernos.
        </li>
      </ul>
    </div>
    <div class="col-12 table-white">
      <b>Características:</b>
      <br />
      Dirigido a: Alta autoridad, funcionarios/as y personas designado para la
      implantación del Sistema de Gestión para Resultados en el Sector
      Público.<br /><br />
      Garantía de satisfacción: Avalamos la transmisión de conocimientos a
      través der una metodología que permita elegir la información para el
      diseño de productos o resultados.<br /><br />
      Constancia: Al término del curso se otorgará una constancia de
      participación con valor curricular, con la insignia de Visión y Estrategia.
    </div>
    <div class="col-12 table-blue">
      <b>Temario:</b>
      <br />
      <b>Herramienta de partida:</b> Comprensión de un Sistema de Gestión para
      Resultados.
      <ul class="ul-clean">
        <li>1.6. Participación y tipos de participación</li>
        <li>1.7. Consulta ciudadana</li>
        <li>1.8. Sistemas de innovación en participación ciudadana</li>
        <li>1.9. Partes interesadas</li>
        <li>1.10. Definición de un Sistema de Gestión para Resultados</li>
      </ul>
      <b>
        Módulo 1: Planeación de un Sistema de Administración por Resultados
      </b>
      <ul class="ul-clean">
        <li>1.1 Planeación estratégica</li>
        <li>1.2 Planeación operativa</li>
        <li>1.3 Planeación participativa</li>
        <li>1.4 Metodología del Marco Lógico (MML)</li>
        <li>1.5 Gestión de recursos</li>
        <li>1.6 Resultados comprometidos</li>
      </ul>
    </div>
    <div class="col-12 table-white">
      <b>Horas de duración:</b>3 días 9 horas (3 horas por día)
    </div>
    <div class="col-12 table-blue">
      <b>Fecha de realización: </b>Del 2 al 4 de mayo de 2023
    </div>
    <!--Table Módulo I end-->
  </div>
</template>
        
<script>
export default {
  name: "CursoView",
};
</script>
        
<style>
</style>