<template>
  <div class="container-fluid">
    <!--Table Módulo VI -->
    <div class="col-12 table-title">
      Nombre del curso: Módulo VI. Gestión de la evidencia
    </div>
    <div class="col-12 table-white">
      <b>Objetivo:</b>
      <ul>
        <li>
          Comprender el marco conceptual en el que se sustenta la AGpR y conocer
          la situación actual y los desafíos que enfrentan los gobiernos en la
          implementación de los cambios institucionales para mejorar la
          efectividad de estos, mediante la implantación del Sistema de gestión
          para resultados en el sector público.
        </li>
      </ul>
    </div>
    <div class="col-12 table-blue">
      <b>Descripción:</b>
      <ul>
        <li>Reconocer las características de los gobiernos.</li>
        <li>
          Identificar el aporte de la AGpR a los procesos gubernamentales.
        </li>
        <li>Conocer elementos que definen el origen de la AGpR.</li>
        <li>
          Identificar la situación actual de los gobiernos respecto al
          desarrollo e implementación de la AGpR.
        </li>
        <li>
          Identificar las fortalezas y debilidades de los sistemas de
          planificación, presupuesto, gestión financiera, gestión de programas y
          proyectos y seguimiento - evaluación de los gobiernos.
        </li>
      </ul>
    </div>
    <div class="col-12 table-white">
      <b>Características:</b>
      <br />
      Dirigido a: Alta autoridad, funcionarios/as y personas designado para la
      implantación del Sistema de Gestión para Resultados en el Sector Público.
      <br /><br />
      Garantía de satisfacción: Avalamos la transmisión de conocimientos a
      través der una metodología que permita elegir la información para el
      diseño de productos o resultados.<br /><br />
      Constancia: Al término del curso se otorgará una constancia de
      participación con valor curricular, con la insignia de Visión y Estrategia.
    </div>
    <div class="col-12 table-blue">
      <b>Temario:</b>
      <br />
      <b> Módulo 6: Gestión de la evidencia </b>
      <ul class="ul-clean">
        <li>6.1. Conceptualización de evaluación.</li>
        <li>6.2. Herramientas de evaluación.</li>
        <li>6.3. Metodologías de evaluación.</li>
        <li>6.4. Evaluaciones externas e internas.</li>
        <li>6.5. Evaluación de desempeño.</li>
        <li>6.6. Gestión de la evidencia.</li>
        <li>6.7. Evaluación para la toma de decisiones.</li>
      </ul>
    </div>
    <div class="col-12 table-white">
      <b>Horas de duración:</b> 2 días 8 horas (4 por día)
    </div>
    <div class="col-12 table-blue">
      <b>Fecha de realización: </b>11 y 12 de mayo de 2023
    </div>
    <!--Table Módulo VI end-->
  </div>
</template>
        
<script>
export default {
  name: "CursoView",
};
</script>
        
<style>
</style>