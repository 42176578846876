<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          En el trabajo diario, la evidencia hace la diferencia
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">En el trabajo diario, la evidencia hace la diferencia</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/fist_bump.jpeg"
          class="w-100 my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p>
          <em>
            Es incorrecto siempre, en todas partes, y para todos, creer algo
            basándose en insuficientes evidencias.
          </em>
        </p>
        <p>
          En muy pocos casos logramos identificar logros importantes en nuestro
          trabajo cotidiano, nos preocupamos siempre por cumplir con el trabajo
          que se nos asigna, y aunque muchas veces sabemos que estamos dando un
          valor agregado a la organización, la evidencia de ello no es
          identificable ni por nuestros empleadores, ni por los clientes o
          usuarios.
        </p>
        <p>
          La razón obedece a que los procesos de trabajo carecen de evidencia
          que respalde los avances logrados, prácticamente no gestionamos
          evidencia, solo gestionamos cumplimiento de tareas rutinarias, lo que
          a los ojos de todo mundo se traduce en nulos resultados.
        </p>
        <p>
          La gestión de la evidencia hace referencia al uso adecuado de la
          información, pero no cualquier tipo de información, sino
          específicamente a aquella que es el resultado de procesos críticos y
          metodológicos de análisis. En otras palabras, para que exista una
          buena gestión de la evidencia, debe haber un adecuado proceso que la
          genere.
        </p>
        <p>¿Por qué gestionar la evidencia?</p>
        <p>
          Cuando los resultados obtenidos se quedan en simples datos, no generan
          ningún valor agregado; es como si no se hubieran realizado. Hay que
          recordar hoy tenemos herramientas de gestión como ISO 9001 que permite
          identificar oportunidades de mejora que se materializan siempre y
          cuando se gestionen los resultados de la evaluación para poder
          mejorar: 1) El desempeño, 2) Transparencia y rendición de cuentas, 3)
          Toma de decisiones y 4) Generar conocimiento.
        </p>
        <p>
          Las organizaciones (públicas o privadas) fracasan debido a la falta de
          toma de decisiones que esté basada en evidencia, normalmente las
          decisiones son tomadas por los altos directivos que basan sus
          decisiones en criterios propios.
        </p>
        <p>
          Los nuevos directivos están obligados a identificar distintas fuentes
          de información que pueden ser:
          <b>Evidencia científica</b>, aquella que resulta de investigaciones
          metodológicas comprobables; <b>Evidencia organizativa</b>, datos y
          hechos obtenidos por la propia organización;
          <b>Evidencia empírica</b>, información derivada de experiencias
          profesionales previas; y <b>Evidencia de steakholders</b>, los valores
          y preocupaciones de las personas que serán afectadas por la decisión.
        </p>
        <p>
          La responsabilidad que tiene la persona de gestionar la evidencia es
          tan grande que debe desarrollar una habilidad de análisis con enfoque
          crítico, pues no solo basta con buscar evidencia en fuentes
          confiables, inclusive, se recomienda establecer un Sistema de Gestión
          de la Evidencia, que permita: 1) Identificar a los principales actores
          y establecer compromisos, 2) establecer su uso intencional, 3)
          conceptualizar resultados deseados, 4) involucrar al personal
          operativo, 5) diseñar e implementar sistemas de información, 6)
          prepararse para el uso de la información, 7) analizar y valorar
          información/ Involucrar a los principales actores en el análisis, 8)
          tomar decisiones y reportar resultados, y 9) revisar y evaluar el
          sistema.
        </p>
        <p>
          Su implantación no debe verse como un costo de recursos y tiempo, más
          bien tiene que entenderse como una inversión que permitirá a las
          organizaciones y partes interesadas, optimizar la toma de decisiones y
          al mismo tiempo mejorar los resultados que se desean obtener.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>