<template>
  <div class="container-fluid">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link class="" to="/">Inicio</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link class="" to="/boletines">Boletines</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Evaluar para mejorar la calidad del gasto público.
        </li>
      </ol>
    </nav>
    <h1 class="my-h1">Evaluar para mejorar la calidad del gasto público.</h1>
    <div class="row">
      <div class="col-sm-4 text-center">
        <img
          src="@/assets/img/boletines/checklist.jpeg"
          class="w-100 my-3"
          alt="..."
        />
      </div>
      <div class="col-sm-8 general-text">
        <p style="text-align: center">
          <b>
            “El éxito de un programa podría decidirse por la cantidad de
            presupuesto que se le asigna, sin embargo, la verdadera evaluación
            está en apreciar sus resultados”
          </b>
        </p>
        <p>
          El término “evaluación” se ha ido diluyendo con el pasar del tiempo,
          haciendo que cada vez sea menos preciso entenderlo, en gran parte se
          debe a todo aquello con lo que lo relacionamos; y no está de más decir
          que desde las aulas se generan ideas -erronas- de que evaluar supone
          una oportunidad que es exclusivamente para señalar errores, ¿quién no
          recuerda esa sensación de incertidumbre, incluso temor, cuando se
          acercaban las evaluaciones?
        </p>
        <p>
          Esto mismo pasa diariamente en administración pública, en donde se
          demeritan a aquellas instituciones encargadas de evaluar los programas
          presupuestarios, por el miedo a que las evaluaciones supongan una
          mancha política del quehacer público.
        </p>
        <p>
          Para poder reivindicar el concepto de evaluación, debemos partir de
          entenderlo como una herramienta de mejora continua aplicable a
          cualquier proceso, ya sea en lo público, privado, social o inclusive
          en un ámbito personal; de ahí la famosa frase “lo que se mide, se
          mejora”, pues es precisamente un proceso de evaluación el que permite
          medir si algo se realiza conforme a lo que se planea, y poderlo
          corregir en caso de no ser así.
        </p>
        <p>
          De manera técnica la Comisión Económica para América Latina y el
          Caribe (CEPAL), entiende a la evaluación desde una perspectiva
          instrumental, como un sistema conformado por un conjunto de
          procedimientos técnicos utilizados para la obtención, procesamiento y
          análisis de información relevante. Esta definición engloba dos
          aspectos fundamentales; 1) la evaluación es un proceso técnico, es
          decir, se requiere seguir un rigor metodológico claro respecto al
          desempeño que se busca medir: y 2) el valor agregado que se obtiene de
          una correcta evaluación es el procesamiento de información, la
          trascendencia de evaluar está en el tratamiento que se les da a los
          resultados de una evaluación.
        </p>
        <p>
          En el ámbito público contar con procesos de evaluación idóneos puede
          significar el éxito o fracaso de una administración, pues da un
          panorama detallado sobre si las acciones gubernamentales
          verdaderamente funcionan o solo significan un gasto que no representa
          un impacto real en la vida de las personas.
        </p>
        <p>
          Un gasto público de calidad debe partir de la reflexión crítica, saber
          en qué y cómo se está gastando, pero, sobre todo, conocer a
          profundidad la manera en que los recursos públicos son gestionados y
          aplicados. Para esto no hay mejor herramienta que el Programa Anual de
          Evaluación (PAE), un proceso obligatorio para toda la administración
          pública mexicana y que de acuerdo con el artículo 79 de la Ley General
          de Contabilidad Gubernamental, se tiene hasta el 30 de abril de 2021
          para ser presentado. Más allá de la normatividad, la evaluación
          representa una oportunidad para mejorar la toma de decisiones y, por
          ende, la ejecución del gasto público.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyName",
};
</script>    
<style>
</style>